// Libs
import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// images
import MapImage from 'images/mapsImages/itaim-index.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';

import AddressTimes from 'components/units/unitPages/unit/addressTimes';
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
// import PublicTransportation from 'components/unitPages/details/publicTransportation';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';
import BreadCrumb from 'components/breadCrumb';
import Certificate from 'components/ASCOCertification';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = page => (
  <FeaturedMarketing
    WithoutItemBg
    singleText={getMarkdown(page, 'ckmuy0z2w2fn60c71lhg1o15h')}
    servicesList={getMarkdown(page, 'ckmuy0z2w2fn60c71lhg1o15h', true)}
    asset={getMarkdown(page, 'ckmuy0z2w2fn60c71lhg1o15h', false, true)}
  />
);

const renderPage = (page, isDesktop, location) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <BreadCrumb
        details
        markdown={getMarkdown(page, 'ckmyyycp4qrsh0b327c2qzr4i', true)}
      />
      <DetailsTitle
        titleWidth="34rem"
        titleWidthMobile="16rem"
        markdown={getMarkdown(page, 'ckmyz51fsciwd0b648oqb7j7o', true)}
      />
    </Part>
    {!isDesktop && renderFeaturedMarketing(page)}
    <Container>
      <Part gridColumn={'1 / span 6'}>
        {isDesktop && renderFeaturedMarketing(page)}
        {/* About */}
        <TextSection
          isAbout
          isAboutMobile
          gridArea="ABT"
          doNotApplyDisplayBlock
          isShowing
          markdown={getMarkdown(page, 'ckn7jawaowik50c71oe2yuewk', true)}
        />
        {/* Structure */}
        <TextSection
          markdown={getMarkdown(page, 'ckn7ji7ewiaxz0b648k3xgwox', true)}
          gridArea="STRUC"
          doNotApplyDisplayBlock
          isAboutMobile
          isShowing
        />
        {/* Others Units Mobile */}
        <TextSection
          markdown={getMarkdown(page, 'ckmuyg3282j550c660mbuaigx', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
        />
      </Part>
      <Part gridColumn={'8 / span 5'}>
        <AddressTimes
          isShowingAttention
          markdown={getMarkdown(page, 'ckmuy40nc2gik0c71dy6snkv2', true)}
          fragments={getMarkdown(page, 'ckmuy40nc2gik0c71dy6snkv2')}
          location={{ lat: -23.584682, lng: -46.674659 }}
          zoom={16}
          isDetails
          isWhatsapp
          mapPreview={MapImage}
          isStatic
          isDesktop={isDesktop}
        />
        {/* Schedule */}
        <TextSection
          markdown={getMarkdown(page, 'ckmuyicuo2jw80c80i00qxzui', true)}
          gridArea="SCH"
          isShort
          isAboutMobile
          isShowing
        />
        {/* Parking */}
        <TextSection
          markdown={getMarkdown(page, 'ckmuy89fc2h610b32a0zbadet', true)}
          gridArea="PAR"
          isShort
          isAboutMobile
          isShowing
        />
        {/* Others Units */}
        <TextSection
          markdown={getMarkdown(page, 'ckmuyg3282j550c660mbuaigx', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
          isShowing
          isOthersUnitsMobile
        />
        {isDesktop && (
          <SocialShare location={location} title="Unidade Oncologia Itaim" />
        )}
      </Part>
    </Container>
    {!isDesktop && (
      <SocialShare location={location} title="Unidade Oncologia Itaim" />
    )}
    <Part gridColumn={'2 / -2'}>
      <Certificate marginTop />
    </Part>
  </GridContainer>
);

const OncologiaItaim = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckmuy0z2w2fn60c71lhg1o15h', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckmuxtce02fhb0c66tzf3w2st" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <OncologiaItaim page={response.gcms.site.pages} location={location} />
        );
      }}
    />
  );
};
